<template>
  <div>
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('account_dropdown_menu_pet_records')"
          titlemenu2="Dog Record: Rocky"
          titlemenu3="Diagnoses Overview"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center">
      <DiagnosesTable />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import DiagnosesTable from "./DiagnosesTable.vue";

export default {
  components: {
    HeaderGlobal,
    DiagnosesTable,
  },
  data() {
    return {
      pet: {},
      title2: "",
    };
  },
  created() {
    // var retrievedObject = localStorage.getItem("pet");

    // this.pet = JSON.parse(retrievedObject);

    // this.title2 = this.pet
    //   ? this.pet.species == 1
    //     ? this.$t("global_dog") + " Record: " + this.pet.callName
    //     : this.pet.species == 2
    //     ? this.$t("global_cat") + " Record: " + this.pet.callName
    //     : this.pet.species == 3
    //     ? this.$t("global_ferret") + " Record: " + this.pet.callName
    //     : this.pet.species == 4
    //     ? this.$t("global_bird") + " Record: " + this.pet.callName
    //     : this.$t("global_other") + " Record: " + this.pet.callName
    //   : "";
  },
};
</script>

<style></style>