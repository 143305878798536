<template>
  <div class="w-full md:w-10/12 overflow-x-auto">
    <ul class="bg-white p-4 text-primary font-proximaMedium rounded-sm flex items-center">
      <li class="w-3/12">Disease</li>
      <li class="w-3/12">Diagnosing Clinic</li>
      <li class="w-1/12">Start</li>
      <li class="w-1/12">End</li>
      <li class="w-4/12">Comment / Details</li>
    </ul>
    <ul v-for="(data, i) in diagnosiss" :key="i"
      class="py-6 border-b border-primary text-sm font-proximaLight flex items-center justify-between"
      :class="{ 'text-lightGrey': !data.proofAttach }">
      <li class="w-3/12 px-2 flex items-center justify-between">
        {{ data.disease }}
        <img v-if="data.is_verified" src="@/assets/images/approval-shield-icon.png" alt="approval-shield-icon" />
      </li>
      <li v-if="!data.diagnosticPic" class="w-3/12 text-primary flex items-center">
        <img v-if="data.diagnosticPic" class="pr-1" :src="require('@/assets/images/' + 'pp-1' + '.png')"
          :alt="data.diagnosticPic" />
        {{ data.diagnosis_clinic }}
      </li>
      <li v-else class="w-3/12 text-left flex flex-col">
        {{ data.diagnosis_clinic }}
        <span class="text-xs">{{ data.clinic_email_address }}</span>
      </li>
      <li class="w-1/12">{{ data.date_diagnosis ? data.date_diagnosis.substr(0, 10) : "n / a" }}</li>
      <li class="w-1/12">{{ data.date_end_disease ? data.date_end_disease.substr(0, 10) : "n / a" }}</li>
      <li class="w-4/12 relative" :class="{ 'text-primary': data.commentAttach }">
        {{ data.comment }}
        <TrashIcon v-if="!data.is_verified" iconColor="#0AC0CB" bgColor="#fff" width="26" height="26"
          class="cursor-pointer absolute right-4 -top-1" @click.native="deleteDiag(data)" />
      </li>
    </ul>

    <AddIcon @click.native="submitAdd" class="mt-8 cursor-pointer" width="62" height="62" />

    <!-- add diagnosis  -->
    <ModalsConfirm :show="addDiagnosisModals">
      <div slot="actionQuestion" class="relative">
        <div class="absolute top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="addDiagnosisModals = false">
          ✖
        </div>
      </div>
      <img slot="actionQuestion" src="@/assets/images/diagnosis-icon.png" alt="diagnosis-icon" class="m-auto" />
      <div slot="actionQuestion" class="w-full flex flex-col justify-center items-center">
        <h1 class="mt-4 text-2xl text-subheaderText">Add Disease</h1>
        <FormError :errors="errorForm" />
        <div class="w-10/12 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Disease:</span>
          <input type="text" placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="form.disease" />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Diagnosing Clinic:</span>
          <input type="text" placeholder="Enter name of diagnosing clinic"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="form.diagnosis_clinic" />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Clinic's Email Address:</span>
          <input type="text" placeholder="Enter clinic's email address"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="form.clinic_email_address" />
        </div>
        <div class="mt-8 w-10/12 p-4 bg-white border rounded-md">
          <div class="flex flex-col text-left">
            <span class="mb-1 text-subheaderText">Date of Diagnosis</span>
            <date-picker type="date" format="YYYY-MM-DD" v-model="form.date_diagnosis" placeholder="Select date"
              class="w-full my-1 text-sm"></date-picker>
          </div>
        </div>
        <div class="mt-8 w-10/12">
          <InputRadio title="Add transcript (optional)" opt1="Yes" opt2="No" @input="pickedTranscript" />
        </div>
      </div>
      <div slot="actionText" class="w-10/12 mx-auto pb-6 flex justify-center items-center">
        <BorderedButton class="mr-1" marginT="mt-2" title="Cancel" paddingX="px-6" width="w-6/12"
          @click.native="addDiagnosisModals = false" />
        <SubmitButton class="ml-1" marginT="mt-2" title="Continue" background="bg-success" paddingX="px-6" width="w-6/12"
          @click.native="submitContinue" />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- add diagnosis end -->

    <!-- choose files modals -->
    <ModalsConfirm :show="chooseFileModals">
      <div slot="actionQuestion" class="relative">
        <div class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer" @click="chooseFileModals = false">
          ✖
        </div>
      </div>
      <img slot="actionQuestion" src="@/assets/images/media-icon.png" alt="media-icon" class="m-auto" />
      <div slot="actionQuestion">
        <h1 class="mt-4 font-bold text-2xl">Diagnosis Evidence</h1>
        <p class="mt-4 font-proximaLight text-sm text-subheaderText">
          You must provide evidence of the new diagnosis. Please take a photo or
          upload proof.
          <br /><br />
          You may take a new photo or upload an existing file from your device
          (max 5MB - PDF, PNG, JPG or WEBP format).
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <IconButton component="PaperclipIcon" iconColor="#fff" title="Upload File" background="bg-primary" paddingX="px-8"
          width="w-10/12 md:w-5/12" class="mr-2 py-4" @click.native="openFiles()" />
        <IconButton component="CameraIcon" iconColor="#fff" title="Take Photo" background="bg-primary" paddingX="px-8"
          width="w-10/12 md:w-5/12" class="ml-2" />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- choose files modals end -->

    <!-- preview files modals -->
    <ModalsConfirm :show="confirmFileModals">
      <div slot="actionQuestion" class="relative">
        <div class="absolute -top-5 -right-10 text-lg font-proximaSemibold cursor-pointer"
          @click="confirmFileModals = false">
          ✖
        </div>
      </div>
      <div slot="actionQuestion">
        <img :src="imgUrl" :alt="imgUrl" />
        <h1 class="my-4 text-2xl">Attach Photo?</h1>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <BorderedButton class="mr-1" marginT="mt-2" title="Retake" paddingX="px-6" width="w-6/12"
          @click.native="openFiles()" />
        <SubmitButton class="ml-1" marginT="mt-2" title="Confirm" background="bg-success" paddingX="px-6" width="w-6/12"
          @click.native="openConfirmDiagnosisModals" />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- preview files modals end -->

    <!-- attach transcript  -->
    <ModalsConfirm :show="attachTranscriptModals">
      <div slot="actionQuestion" class="relative">
        <div class="absolute -top-5 -right-10 text-2xl font-proximaSemibold cursor-pointer"
          @click="attachTranscriptModals = false">
          ✖
        </div>
      </div>
      <div slot="actionQuestion" class="w-full flex flex-col justify-center items-center">
        <h1 class="text-2xl text-subheaderText">Attach Transcript</h1>
        <div class="w-full mt-4 text-left flex flex-col">
          <span class="mb-2 text-sm text-subheaderText">Search for transcript to attach:</span>
          <input type="text" placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="searchTranscript"  @change="handleSearch"/>
        </div>
        <div class="h-64 flex">
          <ul class="w-5/12 overflow-auto">
            <li class="px-4 py-1 font-proximaMedium text-left hover:bg-primary-20 rounded-sm cursor-pointer"
              :class="{ 'bg-primary-20': data.highlighted }" v-for="(data, i) in transcriptions" :key="i"
              @click="showWhichTranscript(data)">
              <h5 class="text-primary">{{ data.title }}</h5>
              <span class="text-subheaderText text-xs">{{ data.date_created.substr(0, 10) }}</span>
            </li>
          </ul>
          <div class="w-7/12 px-2 text-sm text-subheaderText text-left font-proximaLight overflow-auto">
            <h5 class="text-xs">
              This is a transcription of the conversation recorded during the
              visit:
            </h5>
            <p class="mt-2">{{ whichTranscript }}</p>
          </div>
        </div>
      </div>
      <div slot="actionText" class="w-10/12 mx-auto pb-6 flex justify-center items-center">
        <BorderedButton class="mr-1" marginT="mt-2" title="Cancel" paddingX="px-6" width="w-6/12"
          @click.native="attachTranscriptModals = false" />
        <SubmitButton class="ml-1" marginT="mt-2" title="Continue" background="bg-success" paddingX="px-6" width="w-6/12"
          @click.native="openChooseFiles" />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- attach transcript end -->

    <!-- confirm add diagnosis modals  -->
    <ModalsConfirm :show="showDiagnosisModalsComp">
      <div slot="actionQuestion" class="relative">
        <div class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="closeConfirmDiagnosisModals">
          ✖
        </div>
      </div>
      <img slot="actionQuestion" src="@/assets/images/diagnosis-icon.png" alt="diagnosis-icon" class="m-auto" />
      <div slot="actionQuestion" class="w-full flex flex-col justify-center items-center">
        <h1 class="mt-4 text-2xl text-subheaderText">Confirm Add Diagnosis</h1>
        <FormError :errors="errorForm" />
        <FormError :errors="getError" />
        <div class="w-10/12 mt-5 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Disease:</span>
          <input type="text" placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="form.disease" />
        </div>
        <div class="w-10/12 mt-5 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Diagnosing Clinic:</span>
          <input type="text" placeholder="Enter name of diagnosing clinic"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="form.diagnosis_clinic" />
        </div>
        <div class="w-10/12 mt-5 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Clinic's Email Address:</span>
          <input type="text" placeholder="Enter clinic's email address"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md" v-model="form.clinic_email_address" />
        </div>
        <div class="mt-3 flex items-center" v-if="transcriptTitle">
          <span class="mr-2 text-primary">{{ transcriptTitle }}</span>
          <img src="@/assets/images/close-icon.png" alt="close-icon" class="cursor-pointer" @click="removeTranscript" />
        </div>
        <div class="mt-3 flex items-center" v-if="uploadedFileName">
          <span class="mr-2 text-primary">{{ uploadedFileName }}</span>
          <img src="@/assets/images/close-icon.png" alt="close-icon" class="cursor-pointer" @click="removeImg" />
        </div>
      </div>
      <div slot="actionText" class="w-10/12 mt-8 mx-auto pb-6 flex justify-center items-center">
        <BorderedButton class="mr-1" marginT="mt-2" title="Cancel" paddingX="px-6" width="w-6/12"
          @click.native="cancelConfirmFile" />
        <SubmitButton class="ml-1" marginT="mt-2" title="Continue" background="bg-success" paddingX="px-6" width="w-6/12"
          @click.native="submitAddFile" />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- confirm add diagnosis modals end -->
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import InputRadio from "../../forms/InputRadio.vue";
import IconButton from "../../buttons/IconButton.vue";
import FormError from "../../warning/FormError.vue";
import { validEmail } from "../../../methods/petMethods";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TrashIcon,
    AddIcon,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    DatePicker,
    InputRadio,
    IconButton,
    FormError
  },
  data() {
    return {
      form: {
        disease: "",
        diagnosis_clinic: "",
        clinic_email_address: "",
        date_diagnosis: null,
        transcript_file_id: null,
        diagnosis_evidence: null,
        userEmailAddress: localStorage.getItem("email"),
        petID: "",

      },
      errors: {},
      addDiagnosisModals: false,
      addTranscript: 1,
      attachTranscriptModals: false,
      searchTranscript: "",
      whichTranscript: "",
      transcriptTitle: "",
      chooseFileModals: false,
      confirmFileModals: false,
      imgUrl: "",
      uploadedFileName: "",
    };
  },
  computed: {
    ...mapGetters({
      getError: "medicalhistory/getError",
      diagnosiss: "medicalhistory/getDiagnosiss",
      transcriptions: "medicalhistory/getTranscriptions",
    }),

    errorForm() {
      return this.$store.state.errorForm;
    },

    showDiagnosisModalsComp() {
      return this.$store.state.showDiagnosisModalsState;
    },
  },

  created() {
    var retrievedObject = localStorage.getItem("pet");

    var pet = JSON.parse(retrievedObject);
    this.form.petID = pet.petID;
    this.getDiagnosisOfPet(pet.petID);
    this.getTranscriptionOfPet(pet.petID);

  },

  methods: {
    ...mapActions({
      getDiagnosisOfPet: "medicalhistory/getDiagnosisOfPet",
      getTranscriptionOfPet: "medicalhistory/getTranscriptionOfPet",
      addDiagnosis: "medicalhistory/addDiagnosis",
      deleteDiagnosis: "medicalhistory/deleteDiagnosis",
      SearchTranscript: "medicalhistory/SearchTranscript",
    }),

    gotoAddVaccine() {
      this.$router.push({ path: "/addvaccination" });
    },
    pickedTranscript(e) {
      this.addTranscript = e;
    },
    openChooseFilesOrTranscript() {
      if (this.addTranscript == 1) {
        this.attachTranscriptModals = true;
        this.addDiagnosisModals = false;
      } else {
        this.chooseFileModals = true;
        this.addDiagnosisModals = false;
      }
    },
    openChooseFiles() {
      this.chooseFileModals = true;
      this.attachTranscriptModals = false;
    },
    showWhichTranscript(i) {
      this.whichTranscript = i.transcript;
      this.toggleHighlight(i);
      this.form.transcript_file_id = i.id;
    },
    toggleHighlight(clickedItem) {
      this.transcriptTitle = clickedItem.title;
      this.transcriptions.forEach((item) => {
        if (item.id === clickedItem.id) {
          item.highlighted = true;
        } else {
          item.highlighted = false;
        }
      });
    },
    removeTranscript() {
      this.transcriptTitle = "";
    },
    removeImg() {
      this.uploadedFileName = "";
      this.form.diagnosis_evidence = null;
      this.imgUrl = "";
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.files[0].name;
      const file = e.target.files[0];
      this.form.diagnosis_evidence = file;
      this.imgUrl = URL.createObjectURL(file);
      this.chooseFileModals = false;
      this.confirmFileModals = true;
    },
    cancelConfirmFile() {
      this.$store.commit("showDiagnosisModals", false);
      this.form.disease = "";
      this.form.diagnosis_clinic = "";
      this.form.clinic_email_address = "";
      this.form.date_diagnosis = "";
      this.form.diagnosis_evidence = null;
      this.imgUrl = "";
      this.removeImg();
      this.removeTranscript();
    },

    openConfirmDiagnosisModals() {
      this.$store.commit("showDiagnosisModals", true);
      this.confirmFileModals = false;
    },

    closeConfirmDiagnosisModals() {
      this.$store.commit("showDiagnosisModals", false);
    },

    submitAdd() {
      this.$store.commit("errorForm", "");
      this.$store.commit("error", "");
      this.form.disease = "";
      this.form.diagnosis_clinic = "";
      this.form.clinic_email_address = "";
      this.form.date_diagnosis = "";
      this.form.transcript_file_id = null;
      this.form.diagnosis_evidence = null;

      this.addDiagnosisModals = true;
    },

    submitContinue() {
      this.$store.commit("errorForm", "");
      const nb=0;

      if (this.form.disease.trim() === "") { this.$store.commit("errorForm", "Disease " + this.$t("global_is_required")); return; }
      if (this.form.diagnosis_clinic.trim() === "") { this.$store.commit("errorForm", "Diagnosing Clinic " + this.$t("global_is_required")); return; }
      if (this.form.clinic_email_address.trim() === "") { this.$store.commit("errorForm", "Clinic's Email Address " + this.$t("global_is_required")); return; }
      if (!validEmail(this.form.clinic_email_address)) { this.$store.commit("errorForm", "Valid Clinic's Email Address required.");  return;}
      if (!this.form.date_diagnosis) { this.$store.commit("errorForm", "Date of Diagnosis " + this.$t("global_is_required")); return; }

      this.openChooseFilesOrTranscript();
    },

    deleteDiag(data) {
      this.deleteDiagnosis(data.id);
    },

    submitAddFile() {
      this.$store.commit("errorForm", "");


      if (this.form.disease.trim() === "") { this.$store.commit("errorForm", "Disease " + this.$t("global_is_required")); return; }
      if (this.form.diagnosis_clinic.trim() === "") { this.$store.commit("errorForm", "Diagnosing Clinic " + this.$t("global_is_required")); return; }
      if (this.form.clinic_email_address.trim() === "") { this.$store.commit("errorForm", "Clinic's Email Address " + this.$t("global_is_required")); return; }
      if (!validEmail(this.form.clinic_email_address)) { this.$store.commit("errorForm", "Valid Clinic's Email Address required.");  return;}
      if (!this.form.date_diagnosis) { this.$store.commit("errorForm", "Date of Diagnosis " + this.$t("global_is_required")); return; }
      if (!this.form.diagnosis_evidence) { this.$store.commit("errorForm", "Attach Proof is " + this.$t("global_is_required")); return; }

      this.form.date_diagnosis = this.form.date_diagnosis ? moment(this.form.date_diagnosis).format(
        "YYYY-MM-DD"
      ) : null;

      this.addDiagnosis(this.form);

      /*  this.form.disease = "";
       this.form.diagnosis_clinic = "";
       this.form.clinic_email_address = "";
       this.form.date_diagnosis = "";
       this.form.transcript_file_id = null;
       this.form.diagnosis_evidence = null; */
    },
    handleSearch(){
      this.SearchTranscript(this.searchTranscript);
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100%;
}

.mx-input {
  background: rgba(156, 163, 175, 0.2);
  border: none;
}

.mx-datepicker-popup {
  z-index: 9999;
}
</style>