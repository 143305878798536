<template>
  <div class="my-6">
    <DiagnosesSection />
  </div>
</template>

<script>
import DiagnosesSection from "../components/medicalhistorydashboard/diagnosesoverview/DiagnosesSection.vue";

export default {
  components: {
    DiagnosesSection
  },
  created() {
    
  },
};
</script>